import { createMuiTheme } from "@material-ui/core/styles";
import indigo from "@material-ui/core/colors/indigo";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

// This allows us to wrap the entire application in our custom theme
export default createMuiTheme({
  palette: {
    primary: indigo,
    secondary: {
      light: green[300],
      main: green[600],
      dark: green[700],
    },
    text: {
      secondary: grey[500]
    },
    background: {
      default: "#f1f1f1",
    },
  },
  charts: {
    pie: indigo
  },
  typography: {
    useNextVariants: true,
  },
});
