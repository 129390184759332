import React, { Suspense } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useAuth0 } from "./hooks/useAuth0";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./components/NotFound";
import Loading from "./components/Loading";
import theme from "./theme";

const Home = React.lazy(() => import("./pages/Home/Home"));
const GroundwaterElevationDashboard = React.lazy(() => import("./pages/GroundwaterElevation/Dashboard"));
const GroundwaterElevationManageData = React.lazy(() => import("./pages/GroundwaterElevation/ManageData"));
const GroundwaterElevationImportData = React.lazy(() => import("./pages/GroundwaterElevation/ImportData"));
const GroundwaterExtractionDashboard = React.lazy(() => import("./pages/GroundwaterExtraction/Dashboard"));
const GroundwaterExtractionManageData = React.lazy(() => import("./pages/GroundwaterExtraction/ManageData"));
const GroundwaterExtractionImportData = React.lazy(() => import("./pages/GroundwaterExtraction/ImportData"));
const WaterQuality = React.lazy(() => import("./pages/WaterQuality/WaterQuality"));
const ExportComplianceData = React.lazy(() => import("./pages/ExportComplianceData/ExportComplianceData"));

const App = () => {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            <PrivateRoute
                path="/"
                exact
                component={Home}
            />
            <PrivateRoute
              path="/groundwater-elevation"
              exact
              component={GroundwaterElevationDashboard}
            />
            <PrivateRoute
                path="/groundwater-elevation/manage-data"
                exact
                component={GroundwaterElevationManageData}
            />
            <PrivateRoute
                path="/groundwater-elevation/import-data"
                exact
                component={GroundwaterElevationImportData}
            />
            <PrivateRoute
              path="/groundwater-extraction"
              exact
              component={GroundwaterExtractionDashboard}
            />
            <PrivateRoute
              path="/groundwater-extraction/manage-data"
              exact
              component={GroundwaterExtractionManageData}
            />
            <PrivateRoute
              path="/groundwater-extraction/import-data"
              exact
              component={GroundwaterExtractionImportData}
            />
            <PrivateRoute
              path="/water-quality"
              exact
              component={WaterQuality}
            />
            <PrivateRoute
                path="/reporting"
                exact
                component={ExportComplianceData}
            />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </MuiThemeProvider>
  );
};

export default App;
